import React from 'react';
import styles from './App.module.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RedirectPage, {
  ContactPage,
  FaviconSwitcher,
  Footer,
  Header,
  MainPage,
  PersonvernPage,
  TeamPage,
  UseCases,
} from '@app/components';
import usePageTracking, { useWindowWidth } from '@app/hooks';

function App() {
  const windowWidth = useWindowWidth();
  usePageTracking();

  return (
    <div className={styles.app}>
      <Router>
        <FaviconSwitcher />
        <div style={{ background: '#F6F6F5' }}>
          <Header windowWidth={windowWidth} />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/om-oss/hvem-er-vi" element={<TeamPage />} />
            <Route path="/om-oss/personvern" element={<PersonvernPage />} />
            <Route path="/om-oss/kontakt-oss" element={<ContactPage />} />
            <Route path="/bruksomraader" element={<UseCases />} />
            <Route path="/doctors" element={<RedirectPage />} />
            <Route path="/doctors/login" element={<RedirectPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
