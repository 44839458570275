import { useEffect } from 'react';
import fbq from '@app/lib';

const usePageTracking = () => {
  useEffect(() => {
    fbq('track', 'PageView');
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: window.location.pathname,
      },
    });
  }, [window.location.pathname]);
};

export default usePageTracking;
