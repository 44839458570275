import React, { FC } from 'react';
import fastlegeFemale1 from '@app/images/quotes/quote_lege_female1.svg';
import fastlegeMale1 from '@app/images/quotes/quote_lege_male1.svg';
import fastlegeMale2 from '@app/images/quotes/quote_lege_male2.svg';
import fastlegeFemale2 from '@app/images/quotes/quote_lege_female2.svg';
import QuoteCardsDesktop from './QuoteCardsDesktop';
import i18n from '@app/lib/i18n';

interface QuotesInterface {
  windowWidth: number;
}

const quotes = [
  {
    name: 'Sondre Rønning',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Strandsiden Legesenter',
    }),
    img: fastlegeMale2,
    quote: i18n.t('pages.home.quotes.doctor_quotes.1'),
    // placement: 1,
  },
  {
    name: 'Nirthiga Srikanthan',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Lindås Legekontor',
    }),
    img: fastlegeFemale2,
    quote: i18n.t('pages.home.quotes.doctor_quotes.2'),
    // placement: 2,
  },
  {
    name: 'Michael Nesland',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Eidskog Legesenter',
    }),
    img: fastlegeMale1,
    quote: i18n.t('pages.home.quotes.doctor_quotes.3'),
    // placement: 3,
  },
  {
    name: 'Normund Svoen',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Sunnfjord Medisinske Senter',
    }),
    img: fastlegeMale2,
    quote: i18n.t('pages.home.quotes.doctor_quotes.4'),
    // placement: 4,
  },
  {
    name: 'Kristian Myklevoll',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Øyo Legekontor',
    }),
    img: fastlegeMale2,
    quote: i18n.t('pages.home.quotes.doctor_quotes.10'),
    // placement: 4,
  },
  {
    name: 'David Myklatun',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Morvik Legekontor',
    }),
    img: fastlegeMale1,
    quote: i18n.t('pages.home.quotes.doctor_quotes.5'),
    // placement: 6,
  },
  {
    name: 'Håkon Risnes',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Førde Legekontor',
    }),
    img: fastlegeMale2,
    quote: i18n.t('pages.home.quotes.doctor_quotes.6'),
    // placement: 7,
  },
  {
    name: 'Sturla Slotsvik',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Sørbyen Legegruppe',
    }),
    img: fastlegeMale1,
    quote: i18n.t('pages.home.quotes.doctor_quotes.7'),
    // placement: 8,
  },
  {
    name: 'Ingebjørg Laupsa Helvik',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Fjellsiden Legesenter',
    }),
    img: fastlegeFemale1,
    quote: i18n.t('pages.home.quotes.doctor_quotes.8'),
    // placement: 5,
  },
  {
    name: 'Kristian Oppedal',
    position: i18n.t('pages.home.quotes.position', {
      place: 'Stokka Legesenter',
    }),
    img: fastlegeMale1,
    quote: i18n.t('pages.home.quotes.doctor_quotes.9'),
    // placement: 5,
  },
];

const Quotes: FC<QuotesInterface> = ({ windowWidth }) => {
  return (
    <>
      <QuoteCardsDesktop quotes={quotes} windowWidth={windowWidth} />
    </>
  );
};

export default Quotes;
