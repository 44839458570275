import styles from './VideoSection.module.css';
import { useMediaQuery } from 'react-responsive';
import React from 'react';

const VideoSection: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const desktopVideo = (
    <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <iframe
        src="https://player.vimeo.com/video/952379084?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0&transparent=0"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title="Noteless"
      ></iframe>
    </div>
  );

  const mobileVideo = (
    <div style={{ padding: '125% 0 0 0', position: 'relative' }}>
      <iframe
        src="https://player.vimeo.com/video/952375168?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0&transparent=0"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title="Noteless"
      ></iframe>
    </div>
  );

  return (
    <div className={styles.videoSectionContainer}>
      <div className={styles.videoSectionInnerContainer}>
        <div className={styles.videoContainer}>
          {isMobile ? mobileVideo : desktopVideo}
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
