import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PLATFORM_URL_LOGIN } from '@app/lib';

const RedirectPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log('Current path', pathname);
    if (pathname.includes('doctors')) {
      console.log('Redirecting to login', PLATFORM_URL_LOGIN);
      window.location.href = PLATFORM_URL_LOGIN;
    }
  }, [pathname]);

  return <div></div>;
};

export default RedirectPage;
