import fbq from '@app/lib';

const trackStartTrialClick = () => {
  fbq('track', 'StartTrial');
  trackClickWithGtm({
    event: 'StartTrialClick',
    eventLabel: 'StartTrial',
  });
};

const trackContactClick = () => {
  fbq('track', 'Contact');
  trackClickWithGtm({
    event: 'ContactClick',
    eventLabel: 'Contact',
  });
};

const trackLoginClick = () => {
  fbq('track', 'SubmitApplication');
  trackClickWithGtm({
    event: 'LoginClick',
    eventLabel: 'Login',
  });
};

interface ITrackClickWithGtm {
  event: string;
  eventLabel: string;
}
const trackClickWithGtm = ({ event, eventLabel }: ITrackClickWithGtm) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event,
      eventCategory: 'Button',
      eventAction: 'Click',
      eventLabel,
    });
  }
};

export { trackStartTrialClick, trackContactClick, trackLoginClick };
