import styles from './ContactPage.module.css';
import contactIllustration from '@app/images/contact_page.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { trackContactClick } from '@app/util';
import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Kontakt Oss | Noteless</title>
        <meta
          name="description"
          content="Noteless effektiviserer administrativt arbeid for helsepersonell ved hjelp av kunstig intelligens. Prøv Noteless gratis i 3 uker og opplev en enklere hverdag."
        />
        <link rel="canonical" href="https://www.noteless.no/" />
      </Helmet>
      <div className={styles.contactPageContainer}>
        <div className={styles.contactPageInnerContainer}>
          <div className={styles.contactPageContentContainer}>
            <div className={styles.contactPageContentInnerContainer}>
              <div className={styles.contactPageContent}>
                <div className={styles.contactPageHeaderContainer}>
                  <p className={styles.contactPageSubHeader}>
                    {t('pages.contact.sub_header')}
                  </p>
                  <h1 className={styles.contactPageHeader}>
                    {t('pages.contact.header')}
                  </h1>
                </div>
                <div className={styles.contactPageContactInfo}>
                  <p className={styles.contactPageContactInfoText}>
                    {t('pages.contact.text')}
                  </p>
                  <ul className={styles.contactPageContactInfoList}>
                    <li className={styles.contactPageContactInfoTextBold}>
                      {t('pages.contact.contact_us')}
                    </li>
                    <li>
                      <div
                        className={styles.contactPageContactInfoListItemWrapper}
                      >
                        <p className={styles.contactPageContactInfoText}>
                          {t('pages.contact.email')}
                          <a
                            href="mailto:post@noteless.no"
                            className={styles.contactPageContactInfoEmail}
                            onClick={trackContactClick}
                          >
                            post@noteless.no
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div
                        className={styles.contactPageContactInfoListItemWrapper}
                      >
                        <p className={styles.contactPageContactInfoText}>
                          {t('pages.contact.phone')} +47 40 30 58 84
                        </p>
                      </div>
                    </li>
                  </ul>
                  <p className={styles.contactPageContactInfoText}>
                    {t('pages.contact.info_text')}
                  </p>
                </div>
                <div className={styles.contactPageContactImageContainer}>
                  <img
                    src={contactIllustration}
                    alt={t('pages.contact.contact_illustration')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
