// src/components/FaviconSwitcher.tsx
import React, { useEffect } from 'react';
import useDarkMode from '@app/hooks/useDarkMode';

const FaviconSwitcher: React.FC = () => {
  const isDarkMode = useDarkMode();

  useEffect(() => {
    const updateFavicon = (href: string) => {
      let link: HTMLLinkElement | null =
        document.querySelector("link[rel*='icon']");
      if (link) {
        link.href = href;
      } else {
        link = document.createElement('link');
        link.rel = 'icon';
        link.href = href;
        document.head.appendChild(link);
      }

      // Ensure all other favicon links are updated or removed
      const otherFavicons: NodeListOf<HTMLLinkElement> =
        document.querySelectorAll(
          "link[rel*='icon']:not([href='" + href + "'])",
        );
      otherFavicons.forEach((icon) => {
        if (icon) icon.href = href;
      });
    };

    const faviconHref = isDarkMode ? '/favicon-dark.svg' : '/favicon-light.svg';
    updateFavicon(faviconHref);
  }, [isDarkMode]);

  return null;
};

export default FaviconSwitcher;
