import styles from './Personvern.module.css';
import { ReactComponent as SafetyCheck } from '@app/images/safety_check.svg';
import { ReactComponent as FileLock } from '@app/images/file_lock.svg';
import dla_piper from '@app/images/partners/dla_piper.png';
import { useNavigate } from 'react-router-dom';
import arrowRight from '@app/images/arrow-16px.svg';
import { useTranslation } from 'react-i18next';
import i18n from '@app/lib/i18n';
import React from 'react';

const sectionContent = {
  firstSection: {
    icon: <SafetyCheck className={styles.landingPagePersonvernSectionIcon} />,
    title: i18n.t('pages.home.privacy.content.0.title'),
    text: i18n.t('pages.home.privacy.content.0.text'),
  },
  secondSection: {
    icon: <FileLock className={styles.landingPagePersonvernSectionIcon} />,
    title: i18n.t('pages.home.privacy.content.1.title'),
    text: i18n.t('pages.home.privacy.content.1.text'),
  },
};

const Personvern = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.landingPagePersonvernContainer}>
      <div className={styles.landingPagePersonvernContainerContent}>
        <div className={styles.landingPagePersonvernHeader}>
          <h2 className={styles.landingPagePersonvernHeaderTitle}>
            {t('pages.home.privacy.title')}
          </h2>
          <p className={styles.landingPagePersonvernHeaderText}>
            {t('pages.home.privacy.text')}
          </p>
        </div>
        <div className={styles.landingPagePersonvernSectionContainer}>
          {Object.values(sectionContent).map((section, index) => (
            <div key={index} className={styles.landingPagePersonvernSection}>
              <div className={styles.landingPagePersonvernSectionHeader}>
                {section.icon}
                <h3>{section.title}</h3>
              </div>
              <p className={styles.landingPagePersonvernSectionText}>
                {section.text}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.landingPagePersonvernPartners}>
          <div className={styles.landingPagePersonvernPartnersInnerContainer}>
            <div
              className={styles.landingPagePersonvernPartnersContentContainer}
            >
              <h3 className={styles.landingPagePersonvernPartnersContentTitle}>
                {t('sections.privacy_partner.title')}
              </h3>
              <p className={styles.landingPagePersonvernPartnersContentText}>
                {t('sections.privacy_partner.content', {
                  company: 'Advokatfirma DLA Piper',
                })}
              </p>
            </div>
            <img
              className={styles.landingPagePersonvernPartnersImage}
              src={dla_piper}
              alt="DLA PIPER"
            />
          </div>
        </div>

        <div className={styles.landingPagePersonvernReadMore}>
          <div
            className={styles.landingPagePersonvernReadMoreTextContainer}
            onClick={() => navigate('/om-oss/personvern')}
          >
            <h3>{t('buttons.read_more')}</h3>
            <img
              src={arrowRight}
              alt=""
              className={styles.landingPagePersonvernReadMoreArrow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Personvern;
