import eitri from '@app//images/partners/eitri.png';
import noteless_logo from '@app/images/noteless_logo.svg';
import aleap from '@app/images/partners/aleap.png';
import ntnuDriv from '@app/images/partners/driv.png';
import farvatn from '@app/images/partners/farvatn.png';
import innovasjonNorge from '@app/images/partners/innovasjon_norge.svg';
import nscc from '@app/images/partners/nscc.png';
import vis from '@app/images/partners/vis.png';
import webmed from '@app/images/partners/webmed.png';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLinkedin } from 'react-icons/fa';
import { LuInstagram } from 'react-icons/lu';
import styles from './Footer.module.css';

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerInnerContainer}>
        <img className={styles.footerLogo} src={noteless_logo} alt="Noteless" />
        <div className={styles.footerContactSection}>
          <p className={styles.footerContactSectionHeader}>
            {t('nav.contact_upper')}
          </p>
          <div className={styles.footerContactSectionContentResponsive}>
            <div className={styles.footerContactSectionContent}>
              <p>+47 40 30 58 84</p>
              <p className={styles.footerContactSectionMail}>
                post@noteless.no
              </p>
              <p>Org nr: 932 320 061</p>
            </div>
            <div className={styles.footerWhereSectionContent}>
              <p className={styles.footerWhereSectionContentTown}>OSLO</p>
              <a
                className={styles.footerWhereSectionContentName}
                href="https://exchange.aleap.no/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Aleap Medical Incubator
              </a>
              <p className={styles.footerWhereSectionContentAdress}>
                Gaustadalléen 21,
              </p>
              <p className={styles.footerWhereSectionContentAdress}>
                0349 Oslo
              </p>
            </div>
            <div className={styles.footerWhereSectionContent}>
              <p className={styles.footerWhereSectionContentTown}>BERGEN</p>
              <a
                className={styles.footerWhereSectionContentName}
                href="https://www.eitrilab.no/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Eitri Medical Incubator
              </a>
              <p className={styles.footerWhereSectionContentAdress}>
                Haukelandsbakken 31,
              </p>
              <p className={styles.footerWhereSectionContentAdress}>
                5009 Bergen
              </p>
            </div>
          </div>
          <div className={styles.footerContactSectionSocialMedia}>
            <a
              href="https://www.linkedin.com/company/notelesshealth/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                className={styles.footerContactSectionContentLinkedIn}
              />
            </a>
            <a
              href="https://www.instagram.com/noteless.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LuInstagram
                className={styles.footerContactSectionContentInstagram}
              />
            </a>
          </div>
        </div>
        <div className={styles.footerCoOwnersContainer}>
          <p className={styles.footerCoOwnersHeader}>{t('nav.owners_upper')}</p>
          <div className={styles.footerCoOwnersContent}>
            <a
              href="https://www.visinnovasjon.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.footerCoOwnersVis} src={vis} alt="Vis" />
            </a>
            <a
              href="https://www.farvatnventure.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerCoOwnersFarvatn}
                src={farvatn}
                alt="Farvatn Venture"
              />
            </a>
          </div>
        </div>
        <div className={styles.footerPartners}>
          <p className={styles.footerPartnersHeader}>
            {t('nav.partners_upper')}
          </p>
          <div className={styles.footerPartnersLogoContainer}>
            <a
              href="https://innovasjonnorge.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerPartnersLogoIN}
                src={innovasjonNorge}
                alt="Innovasjon Norge"
              />
            </a>
            <a
              href="http://www.aleap.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerPartnersLogoAleap}
                src={aleap}
                alt="Aleap"
              />
            </a>
            <a
              href="https://www.eitrilab.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerPartnersLogoEitri}
                src={eitri}
                alt="Eitri"
              />
            </a>
            <a
              href="https://www.smartcarecluster.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerPartnersLogoNscc}
                src={nscc}
                alt="Norwegian Smart Care Cluster"
              />
            </a>
            <a
              href="https://www.ntnu.no/mh/innovasjon/drivntnu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerPartnersLogoDriv}
                src={ntnuDriv}
                alt="NTNU Driv"
              />
            </a>
            <a
              href="https://webmed.no/"
              target="_blank"
              rel="noopener
              noreferrer"
            >
              <img
                className={styles.footerPartnersLogoWebMed}
                src={webmed}
                alt="WebMed"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
